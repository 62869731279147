<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
<v-row v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'">
                  <v-col>
                    <v-text-field
                      v-model="vm.incidentID"
                      label="Reportable Incident ID"
                      v-validate="'required'"
                      data-vv-as="Reportable Incident ID"
                      name="incidentID"
                      :error-messages="errors.collect('incidentID')"
                    ></v-text-field>
                  </v-col>
</v-row>
<v-row v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'">
                  <v-col>
                    <v-text-field
                      v-model="vm.internalReferenceNumber"
                      label="Providers Internal Reference Number"
                      v-validate="'required'"
                      data-vv-as="Providers Internal Reference Number"
                      name="internalReferenceNumber"
                      :error-messages="errors.collect('internalReferenceNumber')"
                    ></v-text-field>
                  </v-col>
</v-row>
      <v-col cols="12">
        <v-text-field
          v-model="vm.incidentLocation"
          label="Incident Location"
          v-validate="'required'"
          data-vv-as="Incident Location"
          name="incidentLocation"
          :error-messages="errors.collect('incidentLocation')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.locationType"
          :items="locationType"
          label="Incident Location Type"
          v-validate="'required'"
          data-vv-as="Incident Location Type"
          name="locationType"
          :error-messages="errors.collect('locationType')"
        ></v-select>
      </v-col>

      <v-col cols="12" md="4" v-if="vm.locationType == 'Other'">
        <v-text-field
          v-model="vm.locationTypeOther"
          label="please specify other location"
          v-validate="'required'"
          data-vv-as="Location Type Other"
          name="locationTypeOther"
          :error-messages="errors.collect('locationTypeOther')"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <h6>time and Date of incident/allegation</h6>
      </v-col>

      <v-col cols="6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field v-model="vm.incidentDate" label="Date of Incident" readonly v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="vm.incidentDate" @input="menu2 = false"></v-date-picker>
        </v-menu>
      </v-col>

      <v-dialog
        ref="incidentTimeMenu"
        v-model="incidentTimeMenu"
        :return-value.sync="vm.incidentTime"
        persistent
        full-width
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field v-model="vm.incidentTime" label="Incident Time" lazy readonly v-on="on"></v-text-field>
        </template>
        <v-col cols="12">
          <v-time-picker
           format="24hr"
            v-if="incidentTimeMenu"
            :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
            v-model="vm.incidentTime"
            no-title="true"
            v-validate="'required'"
            data-vv-as="Incident Time"
            name="incidentTime"
            :error-messages="errors.collect('incidentTime')"
            @click:minute="$refs.incidentTimeMenu.save(vm.incidentTime)"
          ></v-time-picker>
        </v-col>
      </v-dialog>

      <v-col cols="12">
        <v-text-field
          v-model="vm.dateUnknownReason"
          label="If Date unknown, reason why"
          v-validate="'required'"
          data-vv-as="Date Unknown"
          name="dateUnknownReason"
          :error-messages="errors.collect('dateUnknownReason')"
        ></v-text-field>
      </v-col>

<div v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'">


      <v-col cols="6">
        <h6>Time and Date first worker became aware of the incident</h6>
      </v-col>

      <v-col cols="6">
        <v-menu
          v-model="menu4"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field v-model="vm.incidentDateFirstWorker" label="Date of Incident - First Worker Aware" readonly v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="vm.incidentDateFirstWorker" @input="menu4 = false"></v-date-picker>
        </v-menu>
      </v-col>

      <v-dialog
        ref="incidentTimeMenuFirstWorker"
        v-model="incidentTimeMenuFirstWorker"
        :return-value.sync="vm.incidentTimeFirstWorker"
        persistent
        full-width
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field v-model="vm.incidentTimeFirstWorker" label="Incident Time - First Worker Aware" lazy readonly v-on="on"></v-text-field>
        </template>
        <v-col cols="12">
          <v-time-picker
           format="24hr"
           :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
            v-if="incidentTimeMenuFirstWorker"
            v-model="vm.incidentTimeFirstWorker"
            no-title="true"
            v-validate="'required'"
            data-vv-as="Incident Time - First Worker Aware"
            name="incidentTimeFirstWorker"
            :error-messages="errors.collect('incidentTimeFirstWorker')"
            @click:minute="$refs.incidentTimeMenuFirstWorker.save(vm.incidentTimeFirstWorker)"
          ></v-time-picker>
        </v-col>
      </v-dialog>

      <v-col cols="12">
        <v-text-field
          v-model="vm.nameFirstWorkerAware"
          label="Name of the first worker to become aware of the incident"
          v-validate="'required'"
          data-vv-as="Name of the first worker to become aware of the incident"
          name="nameFirstWorkerAware"
          :error-messages="errors.collect('nameFirstWorkerAware')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.roleFirstWorkerAware"
          label="Role of the first worker to become aware of the incident"
          v-validate="'required'"
          data-vv-as="Role of the first worker to become aware of the incident"
          name="roleFirstWorkerAware"
          :error-messages="errors.collect('roleFirstWorkerAware')"
        ></v-text-field>
      </v-col>




</div>

            <v-col cols="6">
        <h6>Time and Date of NDIS Provider became aware of the incident</h6>
      </v-col>

      <v-col cols="6">
        <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field v-model="vm.incidentDateNDISAware" label="Date" readonly v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="vm.incidentDateNDISAware" @input="menu3 = false"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="6">
        <v-dialog
          ref="incidentTimeNDISAwareMenu"
          v-model="incidentTimeNDISAwareMenu"
          :return-value.sync="vm.incidentTimeNDISAware"
          persistent
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field v-model="vm.incidentTimeNDISAware" label="Time" lazy readonly v-on="on"></v-text-field>
          </template>
          <v-time-picker
           format="24hr"
           :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
            v-if="incidentTimeNDISAwareMenu"
            v-model="vm.incidentTimeNDISAware"
            @click:minute="$refs.incidentTimeNDISAwareMenu.save(vm.incidentTimeNDISAware)"
          ></v-time-picker>
        </v-dialog>
      </v-col>

      <v-row
        v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'
      "
      >
        <v-col cols="12">
          <v-text-field
            v-model="vm.nameOfPersonWhoReportedIncident"
            label="Name of first who reported the incident/allegation to key personnel"
            v-validate="'required'"
            data-vv-as="First worker aware"
            name="nameOfPersonWhoReportedIncident"
            :error-messages="errors.collect('nameOfPersonWhoReportedIncident')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.roleOfPersonWhoReportedIncident"
            label="Role of first person who reported the incident/allegation to key personnel"
            v-validate="'required'"
            data-vv-as="Incident Time Aware"
            name="incidentTimeAware"
            :error-messages="errors.collect('incidentTimeAware')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.incidentDescription"
            label="Describe the Incident/allegation"
            rows="5"
            data-vv-as="incidentDescription"
            name="incidentDescription"
            v-validate="'required'"
            :error-messages="errors.collect('incidentDescription')"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.incidentCircumstances"
            label="What were the circumstances leading up to the incident/allegation?"
            rows="5"
            data-vv-as="incidentCircumstances"
            name="incidentCircumstances"
            v-validate="'required'"
            :error-messages="errors.collect('incidentCircumstances')"
          ></v-textarea>
        </v-col>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "incident-category",

  data: () => ({
    vm: [],
    locationType: [
      "Residential address",
      "In the community",
      "Specialist disability accomodation",
      "Services outlet",
      "Other"
    ],
    date: new Date().toISOString().substr(0, 10),
    incidentTimeMenu: false,
    incidentTimeNDISAwareMenu: false,
    
    incidentTimeMenuFirstWorker: false,
    time: null,
    menu2: false,
    menu3: false,
    menu4: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.incidentDetails == undefined
          ? {}
          : this.currentIncident.incidentDetails;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.incidentDetails = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

